import React from 'react'
import { ChakraProvider, CSSReset } from '@chakra-ui/react'
// import customTheme from "./theme/theme"
import { extendTheme } from '@chakra-ui/react'
import Layout from './components/layout'

const theme = extendTheme({
  colors: {
    brand: {
      100: '#f7fafc',
      // ...
      900: '#1a202c',
    },
  },
})

export const wrapPageElement = ({ element }) => {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Layout>{element}</Layout>
    </ChakraProvider>
  )
}

// export const wrapPageElement = ({ element }) => {
//   return <ChakraProvider resetCSS>{element}</ChakraProvider>
// }
