import React from 'react'
import { Link, Text } from '@chakra-ui/layout'
import { Box, Flex } from '@chakra-ui/react'
import {
  Button,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby'
import { MdFavorite } from 'react-icons/md'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { useLocation } from '@reach/router'
import Img from 'gatsby-image'

const Header = () => {
  const bg = useColorModeValue('white', 'gray.800')
  const color = useColorModeValue('gray.800', 'white')
  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "components/images/logo.jpg" }) {
        childImageSharp {
          fixed(quality: 100, width: 103) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const { pathname } = useLocation()

  // Set the path where you want to hide the section
  const hideSectionPath = '/sovie-fragebogen'

  return (
    <Box
      as="header"
      position="fixed"
      top="0"
      left="0"
      right="0"
      width="100%"
      color={color}
      backgroundColor={bg}
      borderTopColor="red.500"
      borderTopWidth="0.375rem"
      borderTopStyle="solid"
      zIndex="1"
    >
      {pathname.startsWith(hideSectionPath) ? null : (
        <Box
          padding="0.5rem"
          backgroundColor="red.100"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          Hier geht's zum&nbsp;
          <Link
            as={GatsbyLink}
            to="/sovie-fragebogen"
            color="red.600"
            display="flex"
            alignItems="center"
            style={{ columnGap: '0.25rem' }}
            textDecoration="none"
            _hover={{ textDecoration: 'underline' }}
          >
            <FaExternalLinkAlt size="0.75rem" />
            <Text fontSize="sm" fontWeight="semibold">
              SPÖ KandidatInnen Fragebogen.
            </Text>
          </Link>
        </Box>
      )}
      <Box
        maxW="75rem"
        height="4.5rem"
        ml="auto"
        mr="auto"
        overflow="hidden"
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          pl="1.5rem"
          pr="1.5rem"
        >
          <Box as={GatsbyLink} mt="1" to="/">
            <Img
              fixed={logo.file.childImageSharp.fixed}
              alt="Sovie Logo"
            ></Img>
          </Box>
          <div className="nav">
            <Flex
              flexDirection="row"
              alignItems="center"
              style={{ columnGap: '1.35rem' }}
            >
              <Button
                as={GatsbyLink}
                to="/mitglied-werden"
                variant="outline"
                size={useBreakpointValue(['sm', 'md'])}
              >
                <Box as="span" mr="2" color="red.500">
                  <MdFavorite />
                </Box>
                Mitglied werden
              </Button>
            </Flex>
          </div>
        </Flex>
      </Box>
    </Box>
  )
}

export default Header
