import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react'
import { MdEmail } from 'react-icons/md'

const Footer = () => {
  return (
    <Box as="footer" minH="4.5rem" textAlign="center">
      <Box pt="12" pb="12" bg="red.500" color="white">
        <Button
          as={GatsbyLink}
          to="/"
          color="red.900"
          size={useBreakpointValue(['sm', 'md'])}
          mb="2rem"
        >
          Zur Startseite
        </Button>
        <Flex
          alignItems="center"
          height="100%"
          justifyContent="center"
          fontSize="sm"
          mb="1rem"
        >
          <Box as={GatsbyLink} to="/" mr="3">
            Startseite
          </Box>
          <Box as="span" mr="3">
            /
          </Box>
          <Box as={GatsbyLink} to="/sovie-fragebogen" mr="3">
            SPÖ KandidatInnen Fragebogen
          </Box>
          <Box as="span" mr="3">
            /
          </Box>
          <Box as={GatsbyLink} to="/mitglied-werden" mr="3">
            Mitglied werden
          </Box>
          <Box as="span" mr="3">
            /
          </Box>
          <Box as={GatsbyLink} to="/impressum" mr="3">
            Impressum
          </Box>
        </Flex>
        <Box fontSize="sm">
          &copy; {new Date().getFullYear()} sovie.or.at
        </Box>
      </Box>
      <Box p="1.4rem">
        <Flex fontSize="sm" justifyContent="center">
          <Box mr="1">Proudly made by </Box>
          <Link color="red.600" href="mailto:ahmet@ozturk.at">
            <Flex alignItems="center">
              <Box mr="1">Ahmet Öztürk</Box>
            </Flex>
          </Link>
        </Flex>
        <Flex justifyContent="center" mt="1">
          <Link color="red.600" href="mailto:ahmet@ozturk.at">
            <Flex alignItems="center">
              <Icon as={MdEmail}></Icon>
            </Flex>
          </Link>
        </Flex>
      </Box>
    </Box>
  )
}

export default Footer
